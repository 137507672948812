import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import UserAPI from './UserAPI';
import styled from 'styled-components';
import { padding, marginNumber, navigationWidthNumber, buttonPaddingNumber, secondaryColor, secMenuWidthNumber, backgroundColor, secondaryBackgroundColor } from '../utils/DisplaySettings';
import userProfileImage from '../images/userprofile.png';

const buttonWidth = '20rem'
const inputWidth = '60rem'


const ContentContainerNoBorder = styled.div`
width: 100%;
display: flex;
justify-content: center;
background: ${backgroundColor};
padding: 1.25rem;
margin-top: 1.25rem;
margin-bottom: 1.25rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${secondaryBackgroundColor};
  border: 0.125rem solid ${secondaryColor};
  border-radius: 0.9375rem;
  box-shadow: 0 0 0.9375rem ${secondaryColor};
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const Profile = () => {
    const [YT_channel_URL, setYT_channel_URL] = useState('none');

    
    const { idToken, refreshTokens } = useAuth();

    const getSettings = async () => {
        const idToken = localStorage.getItem('idToken');
        try {
            if (!idToken) {
                console.error('No token available');
                return;
            }

            console.log('Getting settings');
            const jsonData = await UserAPI(JSON.stringify({ 
                'request_type': 'user', 
                'idToken': idToken,
                'user_request_type': 'getsettings' 
            }));
            
            if (!jsonData) return;
            
            const result = JSON.parse(jsonData.body);
            console.log(result);
            setYT_channel_URL(result.YT_channel_URL);
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    useEffect(() => {
        console.log('Profile component mounted');
        getSettings();
        return () => {
            console.log('Profile component unmounted');
        };
    }, []);

    const setYoutubeChannel = async () => {
        console.log('setYoutubeChannel called');
        const idToken = localStorage.getItem('idToken');
        if (!idToken) {
            console.error('No token available');
            return;
        }

        const youtubeChannel = document.getElementById('embedURLinput')?.value || '';
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'changesetting',
            'setting': 'YT_channel_URL',
            'value': youtubeChannel
        });
        
        try {
            const response = await UserAPI(requestBody);
            setYT_channel_URL(youtubeChannel);
            if (response) {
                console.log('Youtube channel updated successfully');
            }
        } catch (error) {
            console.error('Error updating Youtube channel:', error);
        }
    };

    return (
        <div >
            <ContentContainerNoBorder >
                    <img src={userProfileImage} />
            </ContentContainerNoBorder>
            <div style={{ 
                width: `calc(100% - ${navigationWidthNumber + 2}rem)`, 
                marginLeft: `${navigationWidthNumber + 2}rem`,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center' 
            }}>
                <ContentContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', width: '100%' }}>
                        <input 
                            id="embedURLinput" 
                            type="text" 
                            style={{ width: inputWidth }}
                            placeholder={`Enter youtube channel URL (current: ${YT_channel_URL})`}
                        />
                        <button onClick={setYoutubeChannel} >
                            
                            Set Youtube Channel URL
                        </button>
                    </div>
                </ContentContainer>
            </div>
        </div>
    );
};

export default Profile;