import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import UserAPI from './UserAPI';
import EmotionScanner from './EmotionScanner';
import SongsMenu from './SongsMenu';
import SettingsMenuCreate from './SettingsMenuCreate';
import styled from 'styled-components';
import { padding, marginNumber, navigationWidthNumber, buttonPaddingNumber, secondaryColor, secMenuWidthNumber, backgroundColor, secondaryBackgroundColor } from '../utils/DisplaySettings';
import Leaderboard from './Leaderboard';
import creatorConsoleImage from '../images/creatorConsole.png';


const ButtonColumn = styled.div`
  position: fixed;
  left: ${navigationWidthNumber+marginNumber+buttonPaddingNumber}rem;
  top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: ${secMenuWidthNumber}rem;
  button {
    width: 100%;
  }
`;


const ContentContainerNoBorder = styled.div`
width: 100%;
display: flex;
justify-content: center;
background: ${backgroundColor};
padding: 1.25rem;
margin-top: 1.25rem;
margin-bottom: 1.25rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${secondaryBackgroundColor};
  border: 0.125rem solid ${secondaryColor};
  border-radius: 0.9375rem;
  box-shadow: 0 0 0.9375rem ${secondaryColor};
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const Create = () => {
  const { idToken, accessToken } = useAuth();
  const [sessionOnGoing, setSessionOnGoing] = useState(null);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [songsMenu, setSongsMenu] = useState(false);

  useEffect(() => {
    let mounted = true;

    const getSessionStatus = async () => {
      const requestBody = JSON.stringify({
        'request_type': 'user',
        'idToken': localStorage.getItem('idToken'),
        'user_request_type': 'getsessionstatus'
      });
      const jsonData = await UserAPI(requestBody);
      if (mounted) {
        if (jsonData) {
        const result = JSON.parse(jsonData.body);
        setSessionOnGoing(result.session_ongoing ? true : false);
        }
      }
    };

    getSessionStatus();

    // Cleanup function
    return () => {
      mounted = false;
      setSessionOnGoing(null);
      setSettingsMenu(false);
      setSongsMenu(false);
    };
  }, []);

  const startstopSession = async () => {
    const requestBody = JSON.stringify({
      'request_type': 'user',
      'idToken': localStorage.getItem('idToken'),
      'user_request_type': 'startstopsession'
    });

    // Call API for starting or stopping a session
    const jsonData = await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);

    if (result.session_ongoing) {
      // start session
      setSessionOnGoing(true);
    } else {
      // stop session
      setSessionOnGoing(false);
    }
  };




  const openSettings = async () => {
    setSettingsMenu(!settingsMenu);
  };

  const openSongsMenu = async () => {
    setSongsMenu(!songsMenu);
  };

  const copyOverlayUrl = () => {
    const idToken = localStorage.getItem('idToken');
    const payloadBase64 = idToken.split('.')[1];
    const decodedPayload = JSON.parse(atob(payloadBase64.replace(/-/g, '+').replace(/_/g, '/')));
    const eventId = decodedPayload['cognito:username'];
    const overlayUrl = `${window.location.origin}/overlay/${eventId}?session_name=${eventId}`;
    navigator.clipboard.writeText(overlayUrl);
    alert('Overlay URL copied to clipboard!');
  };


  return (
    <div>

            <ContentContainerNoBorder >
                    <img src={creatorConsoleImage} />
            </ContentContainerNoBorder>
      <div style={{ display: 'flex', minHeight: '100vh', padding: padding }}>
        <ButtonColumn>
          <button onClick={startstopSession} id="sessionButton">
            {sessionOnGoing ? 'Stop Session' : 'Start Session'}
          </button>
          <button onClick={copyOverlayUrl}>Copy overlay URL</button>
          
          <button onClick={openSettings}>{settingsMenu ? 'Close settings' : 'Open settings'}</button>
          <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Open song list'}</button>
        </ButtonColumn>

        <div style={{ 
          width: `calc(100% - ${navigationWidthNumber + secMenuWidthNumber+6}rem)`, 
          marginLeft: `${navigationWidthNumber + secMenuWidthNumber+4}rem`,
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}>

        
          {sessionOnGoing && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

              <ContentContainer>
                <EmotionScanner />
              </ContentContainer>
              
              <ContentContainer>
                <Leaderboard />
              </ContentContainer>
            </div>
          )}
          {settingsMenu && (
            <ContentContainer>
              <SettingsMenuCreate />
            </ContentContainer>
          )}
          {songsMenu && (
            <ContentContainer>
              <SongsMenu />
            </ContentContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
