import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

export default function AuthError() {
  const { error, clearError } = useAuth();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(clearError, 5000); // Clear error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  if (!error) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#ff4444',
        color: 'white',
        padding: '1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        zIndex: 1000,
        maxWidth: '300px',
      }}
    >
      {error}
    </div>
  );
} 