import React, { useEffect, useState } from 'react';
import InfoAPI from './InfoAPI';
import SessionUserUI from './SessionUserUI';
import sessionSelectionImage from '../images/sessionSelection.png';
import sessionDefaultImage from '../images/session_default.png';
import { roundedCornersRadius } from '../utils/DisplaySettings';

// Navigation in open sessions

async function fetchSessionList(setSessionButtons) {
  const requestBody = JSON.stringify({
    request_type: "info",
    info_type: "active_sessions"
  });

  const data = await InfoAPI(requestBody);
  const sessionsListParsed = JSON.parse(data.body);
  const sessionButtons = sessionsListParsed.map((session, index) => (
    <button key={index}>{session}</button>
  ));
  setSessionButtons(sessionButtons);
  window.scrollTo({ top: 0, behavior: 'smooth' });
  }

const Attend = () => {
  const [sessionButtons, setSessionButtons] = useState([]);
  const [showUserUI, setshowUserUI] = useState(false);
  const [showSessionsList, setShowSessionsList] = useState(true);

  useEffect(() => {
    let mounted = true;

    const fetchSessions = async () => {
      if (mounted) {
        await fetchSessionList(setSessionButtons);
      }
    };

    fetchSessions();

    // Cleanup function
    return () => {
      mounted = false;
    };
  }, []);

  const handleSessionButtonClick = (session) => {
    localStorage.setItem('selectedSession', session);
    setShowSessionsList(false);
    setshowUserUI(true);
  };

  return (
    <div>
      {showSessionsList && (
        <>
          <div style={{ textAlign: 'center', marginTop: '1.25rem' }}>
            <img 
              src={sessionSelectionImage} 
              alt="Session Selection" 
              style={{ maxWidth: '100%', height: 'auto', marginBottom: '1.25rem', borderRadius: '0.5rem' }}
            />
          </div>
          <div id="sessionsList" style={{ 
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1.25rem',
            gap: '1.25rem',
          }}>
            {sessionButtons.map((sessionButton, index) => (
              <button
                key={index}
                onClick={() => handleSessionButtonClick(sessionButton.props.children)}
                style={{ 
                  width: '15rem', 
                  height: '15rem', 
                  borderRadius: roundedCornersRadius,
                  backgroundImage: `url(${sessionDefaultImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  fontSize: '3rem',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  lineHeight: '15rem',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                }}
              >
                {sessionButton.props.children}
              </button>
            ))}
          </div>
        </>
      )}
      {showUserUI && (
          <SessionUserUI />
      )}
    </div>
  );
};

export default Attend;