import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import EventOverlay from './components/EventOverlay';
import { AuthProvider } from './contexts/AuthContext';
import AuthError from './components/AuthError';

import Home from './components/Home.jsx';
import Callback from './components/Callback.jsx';
import Attend from './components/Attend.jsx';
import Create from './components/Create.jsx';
import Profile from './components/Profile.jsx';

const App = () => {
  useEffect(() => {
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/attend" element={<Attend />} />
          <Route path="/create" element={<Create />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/overlay/:eventId" element={<EventOverlay />} />
        </Routes>
      </Router>
      <AuthError />
    </AuthProvider>
  );
}

export default App;
