import React from 'react';
import login from '../images/login.png';
import EnvironmentVariables from '../utils/EnvironmentVariables';

const Home = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    return (
        <div style={{
            marginTop: '5vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '0 20px',
            boxSizing: 'border-box'
        }}>
            <img src={login} />

            {!code && (
                <div style={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <button onClick={() => window.location.href = EnvironmentVariables.AuthURL}>
                        Sign up/login
                    </button>
                </div>
            )}
        </div>
    );
}

export default Home;