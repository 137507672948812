const isMobileFunc = () => {
    return window.innerWidth <= 768;
}

const primaryColor = '#fe4462';
const secondaryColor = '#028cd5';
const tertiaryColor = '#8cd2ec';
const backgroundColor = '#000000';
const secondaryBackgroundColor = '#0C0C0C';

const marginNumber = 0.8;
const navigationWidthNumber = 14;
const buttonHeightNumber = 4;
const buttonPaddingNumber = 0.625;

const margin = `${marginNumber}rem`;
const buttonHeight = `${buttonHeightNumber}rem`;
const navigationWidth = `${navigationWidthNumber}rem`;
const padding = `${buttonPaddingNumber}rem ${buttonPaddingNumber*2}rem`;
const buttonPadding = `${buttonPaddingNumber}rem ${buttonPaddingNumber*2}rem`;
const roundedCornersRadius = '0.5rem';

const secMenuWidthNumber = navigationWidthNumber;
const secMenuUserWidthNumber = secMenuWidthNumber+5;
// scanFrequency is the time between each scan of the user's face
const scanFrequency = 250; // in milliseconds

// refreshTime is the time interval between each data fetch from database and refresh of the UI
const refreshTime = 2000; // in milliseconds

export { refreshTime, scanFrequency, primaryColor, secondaryColor, tertiaryColor, margin, navigationWidth, padding,
    buttonHeight, buttonPadding, marginNumber, navigationWidthNumber, buttonHeightNumber,
    buttonPaddingNumber, roundedCornersRadius, secMenuWidthNumber, secMenuUserWidthNumber,
    backgroundColor, secondaryBackgroundColor };
export default isMobileFunc;