import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import UserSongsMenu from './UserSongsMenu';
import UserAPI from './UserAPI';
import TuneCoinImage from '../images/tunecoin.png';
import InfoAPI from './InfoAPI';
import ImageAnalysisAPI from './ImageAnalysisAPI';
import Impressions from './Impressions';
import { refreshTime, scanFrequency } from '../utils/DisplaySettings';
import styled from 'styled-components';
import { 
    navigationWidthNumber, 
    marginNumber, 
    buttonPaddingNumber, 
    secMenuWidthNumber, 
    secondaryColor, 
    padding, 
    secMenuUserWidthNumber, 
    backgroundColor, 
    secondaryBackgroundColor 
} from '../utils/DisplaySettings';
import EmbeddedStream from './EmbeddedStream';
import isMobileFunc from '../utils/DisplaySettings';



const SecColumn = styled.div`
  position: fixed;
  left: ${navigationWidthNumber+marginNumber+buttonPaddingNumber}rem;
  top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: ${secMenuUserWidthNumber}rem;
  button {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${secondaryBackgroundColor};
  border: 0.125rem solid ${secondaryColor};
  border-radius: 0.9375rem;
  box-shadow: 0 0 0.9375rem ${secondaryColor};
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
`;

const HalfContentContainer = styled(ContentContainer)`
  width: 50%;
  margin: 1.25rem 0;
`;

const TopSecRow = styled.div`
  position: fixed;
  top: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 1;
`;

const SecRow = styled.div`
  position: fixed;
  top: 5rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 1;
`;

function generateRandomFileName() {
    const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    const filename = 'frame' + randomNumber + '.jpg';
    return filename;
}

async function getEmotion() {
    const idToken = localStorage.getItem('idToken');
    const sessionname = localStorage.getItem('selectedSession');
    const requestBody = JSON.stringify({
        "request_type": "user",
        "idToken":  idToken,
        "session_name": sessionname,
        "user_request_type": "latest_emotions"
    });
    
    const data = await UserAPI(requestBody);
    console.log(data);
    // Check if we have valid data and body
    if (!data || !data.body || data.body === "{}") {
        localStorage.setItem('head_visible', 'false');
        return;
    }

    // Parse the outer JSON structure
    const result = JSON.parse(data.body);
    
    if (result && result.emotions) {
        // Find emotion with highest confidence value
        const highestEmotion = Object.entries(result.emotions)
            .filter(([emotion]) => emotion !== 'timestamp')
            .reduce((max, [emotion, confidence]) => 
                confidence > max[1] ? [emotion, confidence] : max,
                ['', 0]
            )[0];
        localStorage.setItem('emotion', highestEmotion);

        const head_position = result.head_position;
        const is_nodding = head_position.is_nodding;

        localStorage.setItem('is_nodding', is_nodding);
        localStorage.setItem('head_visible', 'true');
    } else {
        localStorage.setItem('head_visible', 'false');
        localStorage.setItem('emotion', 'unknown');
    }
}

const SessionUserUI = () => {
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const { idToken, refreshTokens } = useAuth();
    const intervalIdRef = useRef(null);
    const [scan, setScan] = useState(false);
    const selectedSession = localStorage.getItem('selectedSession');
    const [songsMenu, setSongsMenu] = useState(false);
    const [mounted, setMounted] = useState(true);

    const [credits, setCredits] = useState(null);
    const [points, setPoints] = useState(null);
    const [lastSongName, setLastSongName] = useState(null);
    const [lastArtist, setLastArtist] = useState(null);
    const [embedding, setEmbedding] = useState(null);
    const [YT_channel_URL, setYT_channel_URL] = useState('none');

    const [headVisible, setHeadVisible] =  useState(localStorage.getItem('head_visible') || null);
    const [currentEmotion, setCurrentEmotion] = useState(localStorage.getItem('emotion') || 'unknown');
    const [isNodding, setIsNodding] = useState(localStorage.getItem('is_nodding') === 'true');

    const isMobile = isMobileFunc();

    const getMedia = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ 
                video: {
                    width: { ideal: 200 }
                }
            });
            setStream(mediaStream);
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                alert('Permission to access camera was denied. Please allow access to the camera and try again.');
            } else if (err.name === 'NotFoundError') {
                alert('No camera device found. Please ensure a camera is connected and try again.');
            } else if (err.name === 'NotReadableError') {
                alert('Camera is already in use by another application. Please close other applications and try again.');
            } else {
                alert('Error accessing media devices: ' + err.message);
            }
        }
    };

    const getEmbedding = async () => {
        const bodyData = JSON.stringify({
            'request_type': 'info',
            'info_type': 'embedding',
            'session_name': localStorage.getItem('selectedSession')
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        const embeddingVar = result.YT_embed_bool;
        const YT_channel_URL = result.YT_channel_URL;
        console.log(YT_channel_URL);
        if (embeddingVar==0) {
            setEmbedding(false);
        }
        else {
            console.log("Embedding is true");
            setEmbedding(true);
        }
        setYT_channel_URL(YT_channel_URL);
    }

    useEffect(() => {
        setMounted(true);
        getMedia();
        getEmbedding();
        const intervalId = setInterval( refreshTime);

        return () => {
            setMounted(false);
            clearInterval(intervalId);
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []); // Remove idToken dependency since we're using localStorage

    useEffect(() => {
        const checkEmotionStatus = () => {
            setHeadVisible(localStorage.getItem('head_visible') || 'false');
            setCurrentEmotion(localStorage.getItem('emotion') || 'unknown');
            setIsNodding(localStorage.getItem('is_nodding') === 'true');
        };

        const emotionInterval = setInterval(checkEmotionStatus, 100); // Check every 100ms

        return () => clearInterval(emotionInterval);
    }, []);

    const handleCapture = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) {
            console.error('No token available');
            return;
        }

        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        const refVideoHeight = 200;
        context.drawImage(video, 0, 0, refVideoHeight * canvas.width / canvas.height, refVideoHeight);
        
        try {
            const blob = await new Promise((resolve) => {
                canvas.toBlob(resolve, 'image/jpeg');
            });
            
            const filename = generateRandomFileName();
            const file = new File([blob], filename, { type: 'image/jpeg' });
            const sessionname = localStorage.getItem('selectedSession');

            const base64Image = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });

            var requestBody = {
                "image": base64Image,
                "metadata": {
                    "idToken": storedToken,
                    "sessionname": sessionname,
                    "timestamp": Date.now(),
                }
            };
            await ImageAnalysisAPI(requestBody);
            await getEmotion();

        } catch (error) {
            console.error('Error in handleCapture:', error);
        }
    };

    const handleScan = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) {
            console.error('No token available');
            return;
        }

        setScan(!scan);
        
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        
        if (!scan && mounted) { // Only start if component is mounted
            intervalIdRef.current = setInterval(handleCapture, scanFrequency);
        }
    };

    const openSongsMenu = async () => {
        setSongsMenu(!songsMenu);
      };

      const openYoutubeChannel = async () => {
        window.open(YT_channel_URL, '_blank');
      };

    const getCredits = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'user',
                'idToken': storedToken,
                'user_request_type': 'getcredits'
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted) {  // Check if component is still mounted
                const result = JSON.parse(jsonData.body);
                if (result && result.credits !== undefined) {  // Validate credits exist
                    setCredits(result.credits);
                }
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    const getPoints = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'user',
                'idToken': storedToken,
                'user_request_type': 'getuserstatus',
                'session_name': selectedSession
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted && jsonData.body) {
                const result = JSON.parse(jsonData.body);
                if (result && result[1] !== undefined) {
                    setPoints(result[1]);
                }
            }
        } catch (error) {
            console.error('Error fetching points:', error);
        }
    };

    const getLastRequest = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'transaction',
                'transaction_type': 'lasttransaction',
                'idToken': storedToken,
                'session_name': selectedSession
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted && jsonData.body) {
                const result = JSON.parse(jsonData.body);
                if (result && result.artist) {
                    setLastArtist(result.artist.replace("%q", "'"));
                    setLastSongName(result.song_name.replace("%q", "'"));
                }
            }
        } catch (error) {
            console.error('Error fetching last request:', error);
        }
    };

    useEffect(() => {
        setMounted(true);

        // Initial fetch
        const fetchInitialData = async () => {
            await getCredits();
            await getPoints();
            await getLastRequest();
        };
        fetchInitialData();

        // Set up intervals for polling
        const creditsInterval = setInterval(getCredits, refreshTime);
        const pointsInterval = setInterval(getPoints, refreshTime);
        const lastRequestInterval = setInterval(getLastRequest, refreshTime);

        // Cleanup function
        return () => {
            setMounted(false);
            clearInterval(creditsInterval);
            clearInterval(pointsInterval);
            clearInterval(lastRequestInterval);
            // Clear the scan interval if it exists
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
            setScan(false); // Reset scan state
        };
    }, []);
            
    return (
        <div>
            {isMobile ? (
                // Mobile Layout
                <>
                    <TopSecRow>          
                        <button onClick={handleScan} id="scanButton">{scan ? 'Stop' : 'Start sharing emotions'}</button>
                        <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Request song'}</button>
                    </TopSecRow>

                    <SecRow style={{ marginTop: '0.5rem' }}>
                        <div id="info_tab" style={{ display: 'flex', flexDirection: 'row', gap: '0.625rem', padding: padding }}>
                            <div style={{ alignItems: 'center', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '0.25rem', padding: '0.25rem', background: secondaryBackgroundColor }}>
                                <p>Selected session: {selectedSession}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.625rem', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '0.25rem', padding: '0.25rem', background: secondaryBackgroundColor }}>
                                {points && <p>Pts : {points}</p>}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.3125rem' }}>
                                    <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />
                                    <p>{credits !== null ? `${credits}` : 'Loading credits...'}</p>
                                </div>
                            </div>
                            <div style={{ alignItems: 'center', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '0.25rem', padding: '0.25rem', background: secondaryBackgroundColor }}>
                                <p>Song requested: {lastSongName !== null ? lastSongName+' by '+lastArtist : 'None'}</p>
                            </div>
                        </div>
                    </SecRow>

                    <div style={{ 
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center',
                        marginTop: '8rem'
                    }}>
                        {/* Rest of mobile content */}
                        {embedding && <EmbeddedStream height={25}/>}
                        <ContentRow>
                            <HalfContentContainer>
                                <div id="emotions" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.625rem' }}>
                                    <div id="webcam" style={{ display: 'flex', justifyContent: 'center' }}>
                                        {stream ? (
                                            <video
                                                autoPlay
                                                playsInline
                                                muted
                                                ref={video => {
                                                    if (video && !video.srcObject) {
                                                        video.srcObject = stream;
                                                        videoRef.current = video;
                                                    }
                                                }}
                                                type="video/webm,video/mp4,video/ogg"
                                            />
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                    {
                                    <div>
                                        {!scan && (
                                            <p>Start sharing emotions to get points</p>
                                        )}
                                        {scan && (      
                                            <>
                                                {headVisible === 'false' && (
                                                    <p>No face recognized...</p>
                                                )}
                                            {headVisible === 'true' && (
                                                <>
                                                    <p>Smiling ? {currentEmotion === 'happy' ? 'Yes' : 'No'}</p>
                                                    <p>Nodding ? {isNodding ? 'Yes' : 'No'}</p>
                                                    <p>Suprised ? {currentEmotion === 'surprised' ? 'Yes' : 'No'}</p>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                    }
                                </div>
                            </HalfContentContainer>

                            <HalfContentContainer>
                                <Impressions />
                            </HalfContentContainer>
                        </ContentRow>

                        {songsMenu && (
                            <ContentContainer>
                                <UserSongsMenu/>
                            </ContentContainer>
                        )}
                    </div>
                </>
            ) : (
                // Desktop Layout (existing layout)
                <>
                    <SecColumn>          
                        <button onClick={handleScan} id="scanButton">{scan ? 'Stop' : 'Start sharing emotions'}</button>
                        <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Request song'}</button>
                        {YT_channel_URL !== 'none' && <button onClick={openYoutubeChannel}>Open Youtube channel</button>}
                        <div id="info_tab" style={{ display: 'flex', flexDirection: 'column', gap: '0.625rem', padding: padding }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Selected session: {selectedSession}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.625rem' }}>
                                {points && <p>Pts : {points}</p>}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.3125rem' }}>
                                    <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />
                                    <p>{credits !== null ? `${credits}` : 'Loading credits...'}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Song requested: {lastSongName !== null ? lastSongName+' by '+lastArtist : 'None'}</p>
                            </div>
                        </div>
                        
                    </SecColumn>

                    <div style={{ 
                        width: `calc(100% - ${navigationWidthNumber + secMenuUserWidthNumber+7}rem)`, 
                        marginLeft: `${navigationWidthNumber + secMenuUserWidthNumber+5}rem`,
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center' 
                    }}>
                        {embedding && (
                            <ContentContainer style={{ height: '50rem' }}>
                                <EmbeddedStream height={50}/>
                            </ContentContainer>
                        )}

                        <ContentRow>
                            <HalfContentContainer>
                                <div id="emotions" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.625rem' }}>
                                    <div id="webcam" style={{ display: 'flex', justifyContent: 'center' }}>
                                        {stream ? (
                                            <video
                                                autoPlay
                                                playsInline
                                                muted
                                                ref={video => {
                                                    if (video && !video.srcObject) {
                                                        video.srcObject = stream;
                                                        videoRef.current = video;
                                                    }
                                                }}
                                                type="video/webm,video/mp4,video/ogg"
                                            />
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                    {
                                    <div>
                                        {!scan && (
                                            <p>Start sharing emotions to get points</p>
                                        )}
                                        {scan && (      
                                            <>
                                                {headVisible === 'false' && (
                                                    <p>No face recognized...</p>
                                                )}
                                            {headVisible === 'true' && (
                                                <>
                                                    <p>Smiling ? {currentEmotion === 'happy' ? 'Yes' : 'No'}</p>
                                                    <p>Nodding ? {isNodding ? 'Yes' : 'No'}</p>
                                                    <p>Suprised ? {currentEmotion === 'surprised' ? 'Yes' : 'No'}</p>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                    }
                                </div>
                            </HalfContentContainer>

                            <HalfContentContainer>
                                <Impressions />
                            </HalfContentContainer>
                        </ContentRow>

                        {songsMenu && (
                            <ContentContainer>
                                <UserSongsMenu/>
                            </ContentContainer>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default SessionUserUI;