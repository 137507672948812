import React, { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { fetchTokens } from '../utils/Auth';
import UserAPI from './UserAPI';

export default function Callback() {
    const { handleLogin } = useAuth();
    const navigate = useNavigate();
    const processedRef = useRef(false);

    useEffect(() => {
        const processAuth = async () => {
            // Prevent double processing
            if (processedRef.current) return;
            processedRef.current = true;

            try {
                const params = new URLSearchParams(window.location.search);
                const code = params.get('code');
                
                if (!code) {
                    throw new Error('No authorization code found');
                }

                console.log('code', code);

                const tokens = await fetchTokens(code);
                
                if (!tokens) {
                    throw new Error('Failed to retrieve tokens');
                }

                handleLogin(tokens);
                createUser();
                navigate('/');

            } catch (error) {
                console.error('Authentication error:', error);
                navigate('/', {
                    state: { error: 'Authentication failed. Please try again.' }
                });
            }
        };

        const createUser = async () => {
            const idToken = localStorage.getItem('idToken');
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': idToken,
                'user_request_type': 'newuser'
            });
            await console.log('API request:', requestBody);
            // Call API for new user creation
            await UserAPI(requestBody);
        }

        processAuth();
        
    }, [handleLogin, navigate]);

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
        }}>
            Processing login...
        </div>
    );
}