import EnvironmentVariables from "../utils/EnvironmentVariables";

const ImageAnalysisAPI = async (requestBody) => {
    const idToken = localStorage.getItem('idToken');
    try {
        const response = await fetch(EnvironmentVariables.ImageAnalysisAPI_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Origin': window.location.origin,
                'Accept': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify(requestBody)
        });
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error calling image analysis API:', error);
        throw error;
    }
};

export default ImageAnalysisAPI;