import React, { useState, useEffect } from 'react';
import UserAPI from './UserAPI';
import { formatTitleToDB, formatTitleToDisplay } from './Functions';
import InfoAPI from './InfoAPI';
import { Title, Text } from './Typography';

const SongsMenu = () => {
    const [songs, setSongs] = React.useState([]);
    const [lastSongName, setLastSongName] = useState(null);
    const [lastArtist, setLastArtist] = useState(null);

    const getSongPlayed = async () => {
        const requestBody = JSON.stringify({
        'request_type': 'info',
          'session_name': JSON.parse(atob(localStorage.getItem('idToken').split('.')[1].replace(/-/g, '+').replace(/_/g, '/')))['cognito:username'],
          'info_type': 'songplayed'
        });
        const jsonData = await InfoAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        
        var song= result.song_name;
        var artist= result.artist_name;
        if (song && artist) {
          song= formatTitleToDisplay(song);
          artist=formatTitleToDisplay(artist);
          setLastSongName(song);
          setLastArtist(artist);
        }
      };

    const loadSongs = async()    => {
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'songs',
            'action': 'scan'
        });
        
    const jsonData=await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);
    setSongs([]);
    result.forEach(element => {
        var title = element[0];
        title= formatTitleToDisplay(title);
        var artist = element[1];
        artist=formatTitleToDisplay(artist);
        setSongs(prevSongs => [...prevSongs, {title: title, artist: artist}]);
    });
   }

    const handleAddSong = async (e) => {
        const idToken = localStorage.getItem('idToken');
        const artist = document.getElementById('artistInput').value;
        const title = document.getElementById('titleInput').value;
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'songs',
            'action': 'add',
            'artist': formatTitleToDB(artist),
            'title': formatTitleToDB(title)
        });
        
        const jsonData = await UserAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        loadSongs();
    }

    const handleRemoveSong = async (index) => {
        const idToken = localStorage.getItem('idToken');
        const artist = songs[index].artist;
        const title = songs[index].title;
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'songs',
            'action': 'delete',
            'artist': formatTitleToDB(artist),
            'title': formatTitleToDB(title)
        });
        const jsonData = await UserAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        loadSongs();
    }

    const handlePlaySong = async (index) => {
        const idToken = localStorage.getItem('idToken');
        const artist = songs[index].artist;
        const title = songs[index].title;
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'event',
            'event_type': 'playsong',
            'artistname': formatTitleToDB(artist),
            'songname': formatTitleToDB(title)
        });
        console.log(requestBody);
        const jsonData = await UserAPI(requestBody);
        getSongPlayed();
    }
    
    useEffect (() => {
       loadSongs();
        getSongPlayed();
    },[]);
    return (
        <div style={{ width: '100rem' }}>
            <div>
              {lastSongName !== null && (
                <p>Playing: {lastSongName + ' by ' + lastArtist}</p>
              )}
            </div>
            <div style={{ margin: '1.25rem 0' }}></div>
            <div>
                
                <input id="artistInput" type="text" placeholder={`Artist`} />
                <input id="titleInput" type="text" placeholder={`Title`} />
                <button onClick={() => handleAddSong()}>Add Song</button>
            </div>
            <div style={{ margin: '1.25rem 0' }}></div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '0.625rem' }}>
                    <div style={{ width: '40%' }}>Title</div>
                    <div style={{ width: '40%' }}>Artist</div>
                    <div style={{ width: '20%' }}></div>
                </div>
                <hr />
                {songs.map((song, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.625rem' }}>
                        <div style={{ width: '40%' }}>{song.title}</div>
                        <div style={{ width: '40%' }}>{song.artist}</div>
                        <div style={{ width: '20%' }}>
                            <button onClick={() => handleRemoveSong(index)}>Remove</button>
                            <button onClick={() => handlePlaySong(index)}>Play</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SongsMenu;