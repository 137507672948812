import React, { useEffect, useState } from 'react';
import EmotionDisplay from './EmotionDisplay';
import UserAPI from './UserAPI';
import { Text } from './Typography';

const EmotionScanner = () => {

    const [showSessionEmotion, setShowSessionEmotion] = useState(false);
    const [emotions, setEmotions] = useState([]);
    const [scanOnGoing, setScanOnGoing] = useState(false);
    const [emotionTimeInterval, setEmotionTimeInterval] = useState(3);
    const [impressions, setImpressions] = useState([]);
    const [showSessionImpressions, setShowSessionImpressions] = useState();

    const getSessionStatus = async () => {
        const requestBody = JSON.stringify({
          'request_type': 'user',
          'idToken': localStorage.getItem('idToken'),
          'user_request_type': 'getsessionstatus'
        });
        // Call API for fetching session status
        const jsonData = await UserAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        let scanOngoingBoolean = false;
        if (result.scan_ongoing) {
            scanOngoingBoolean = true;
        }
        setScanOnGoing(scanOngoingBoolean);
        scanOngoingBoolean && setScanOnGoing(true); // Ensure it's set to true if scanOngoingBoolean is true
        return scanOngoingBoolean;
      };

    const startstopScan = async () => {
        const requestBody = JSON.stringify({
          'request_type': 'user',
          'idToken': localStorage.getItem('idToken'),
          'user_request_type': 'startstopscan'
        });
        // Call API for starting or stopping a scan
        const jsonData = await UserAPI(requestBody);
        console.log(jsonData);
        const result = JSON.parse(jsonData.body);
        if (result.scan_ongoing) {
          scanEmotions();
          scanImpressions();
        }
        await getSessionStatus();
      };


    const scanEmotions = async () => {
        const DBScanOnGoing = await getSessionStatus();

        if (DBScanOnGoing) {
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'event',
                'event_type': 'collectEmotionalReactions'
            });
            // Call API emotion scan 
            const emotionData = await UserAPI(requestBody);
            if (emotionData.body) {
                const resultEmotion = JSON.parse(emotionData.body);
                const emotionsCounts = resultEmotion.emotions_counts;
                const happyCount = emotionsCounts.happy || 0;   
                const surprisedCount = emotionsCounts.surprised || 0;
                const headPositionCounts = resultEmotion.head_position_counts;
                const noddingCount = headPositionCounts.is_nodding || 0;
                setEmotions({ happy: happyCount, nodding: noddingCount, surprised: surprisedCount });
                setShowSessionEmotion(true);
            }
            // Run the scan again after the specified delay
            setTimeout(scanEmotions, emotionTimeInterval * 1000);
        }


    }

    const scanImpressions = async () => {
        const jsonData = await UserAPI(JSON.stringify({'idToken': localStorage.getItem('idToken'),'request_type': 'user','user_request_type': 'getsettings'}));
        console.log(jsonData);
        const result = JSON.parse(jsonData.body);
        const DBScanOnGoing = await getSessionStatus();
        
        if (result.timedelay && DBScanOnGoing) {
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'event',
                'event_type': 'scan',
                'input_type': 'impressions'
            });
            // Call API emotion scan 
            console.log(requestBody);
            const impressionsData = await UserAPI(requestBody);
            console.log(impressionsData);
            if (impressionsData.body) {
                const resultImpressions = JSON.parse(impressionsData.body);

                setImpressions(resultImpressions.impressions);
                setShowSessionImpressions(true);
            }
            // Run the scan again after the specified delay
            setTimeout(scanImpressions, result.timeinterval * 1000);
        }


    }

    
    

    useEffect(() => {
        getSessionStatus();
        return () => {
            UserAPI(JSON.stringify({ 'request_type': 'user', 'idToken': localStorage.getItem('idToken'), 'user_request_type': 'stopscan' }));
            
        }
    }, []);

    return (
        <div>
            <div style={{ display: 'flex'}}>
            <button onClick={startstopScan}>{scanOnGoing ? 'Stop Scan' : 'Start Scan'}</button>
          </div>
        
          {showSessionEmotion && (
            <div>
                {Object.entries(emotions).map(([emotionType, count]) => (
                    count > 0 && (
                        <Text key={emotionType}>
                            {emotionType}: {count} times
                        </Text>
                    )
                ))}
            </div>
            )}
            {showSessionImpressions && (
                <EmotionDisplay emotions={impressions} color='white' role='creator' />
            )}
          
        </div>
    );
};

export default EmotionScanner;