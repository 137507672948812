import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../images/homeIcon.png';
import browseIcon from '../images/browseIcon.png';
import createIcon from '../images/createIcon.png';
import profileIcon from '../images/profileIcon.png';
import logo from '../images/logo.png';
import isMobileFunc from '../utils/DisplaySettings';
import { secondaryColor, margin, navigationWidth, buttonPadding, navigationWidthNumber, buttonPaddingNumber, marginNumber } from '../utils/DisplaySettings';


const buttonWidthNumber = navigationWidthNumber - buttonPaddingNumber*2 - marginNumber*2;

function Navigation() {
    const [isMobile, setIsMobile] = React.useState(isMobileFunc());

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(isMobileFunc());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderLink = (path, label, icon) => (
        <Link 
            to={path} 
            style={{ 
                cursor: 'pointer', 
                transition: 'box-shadow 0.3s', 
                padding: buttonPadding, 
                color: 'white', 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row', // Column layout for mobile
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: isMobile ? 'calc(25%)' : `${buttonWidthNumber}rem`, // 25% for 4 buttons, minus margins
                fontSize: '0.8rem' // Added increased font size
            }}
            onMouseEnter={(e) => !isMobile && (e.currentTarget.style.boxShadow = `0 0 ${marginNumber}rem rgba(254, 68, 98, 1.0)`)}
            onMouseLeave={(e) => !isMobile && (e.currentTarget.style.boxShadow = 'none')}
        >
            <img src={icon} alt={`${label} icon`} style={{ marginBottom: isMobile ? '0.3125rem' : '0', marginRight: isMobile ? '0' : '0.625rem', width: '4rem', height: '4rem' }} />
            <span style={{ fontSize: isMobile ? '1rem' : '1.7rem' }}>{label}</span>
        </Link>
    );

    return (
        <nav style={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'row' : 'column',
            flexWrap: 'nowrap', 
            alignItems: isMobile ? 'center' : 'flex-start',
            position: isMobile ? 'relative' : 'fixed',
            left: 0,
            top: 0,
            height: isMobile ? 'auto' : '100vh',
            width: isMobile ? '100%' : 'auto',
            backgroundColor: '#000',
            padding: isMobile ? '0.625rem' : '1.25rem 0',
            zIndex: 1000,
            borderRight: isMobile ? 'none' : `0.125rem solid ${secondaryColor}`,
            boxShadow: isMobile ? 'none' : `0.125rem 0 0.9375rem ${secondaryColor}, 0 0 0.625rem ${secondaryColor}`
        }}>

            {!isMobile && <img src={logo} alt="Logo" style={{ 
                width: `${navigationWidthNumber}rem`, // Increased from buttonHeight (80px) to 120px
                marginRight: isMobile ? `${buttonPaddingNumber}rem` : '0',
                marginBottom: isMobile ? '0' : `${buttonPaddingNumber}rem`,
                alignSelf: isMobile ? 'center' : 'flex-start'
            }} />}
            {renderLink("/", "Home", homeIcon)}
            {renderLink("/attend", "Attend", browseIcon)}
            {renderLink("/create", "Create", createIcon)}
            {renderLink("/profile", "Profile", profileIcon)}
        </nav>
    );
}

export default Navigation;