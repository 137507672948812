import React from 'react';
import { useState } from 'react';
import InfoAPI from './InfoAPI';
import { useEffect } from 'react';

const EmbeddedStream = ({ height = '40vh' }) => {

    
    const [embeddingURL, setEmbeddingURL] = useState(null);


    useEffect(() => {
        const getEmbeddingURL = async () => {
            try {
                const bodyData = JSON.stringify({
                    'request_type': 'info',
                    'info_type': 'embedding',
                    'session_name': localStorage.getItem('selectedSession')
                });
                const jsonData = await InfoAPI(bodyData);
                const result = JSON.parse(jsonData.body);
                setEmbeddingURL(result.YT_embed_URL);
            } catch (error) {
                console.error('Error fetching embedding URL:', error);
            }
        }
        getEmbeddingURL();
    }, []);

    return (
        <div id="embedding" style={{ 
            display: 'flex', 
            justifyContent: 'center',
            height: `${height}rem`,
            width: `${height*16/9}rem`
        }}>
        {embeddingURL && (
            <div style={{
                width: '100%',
                height: '100%',
                position: 'relative'
            }}>
                <iframe
                    src={embeddingURL} 
                    title="Video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none'
                    }}
                />
            </div>
        )}
        </div>
    );
};

export default EmbeddedStream;
