import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';

const buttonWidth = '20rem'
const inputWidth = '60rem'

const SettingsMenuCreate = () => {
    const { idToken } = useAuth();
    const [timeDelay, setTimeDelay] = useState(null);
    const [timeInterval, setTimeInterval] = useState(null);
    const [embedding, setEmbedding] = useState(null);
    const [embeddingURL, setEmbeddingURL] = useState('none');

    const changeSetting = async (setting) => {
        const fetchedValue = document.getElementById(setting + 'input')?.value || '';
        if (fetchedValue === '') {
            return;
        }
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'changesetting',
            'setting': setting,
            'value': fetchedValue
        });
        await UserAPI(requestBody);
        const jsonData = await UserAPI(JSON.stringify({'request_type':'user', 'idToken': idToken, 'user_request_type': 'getsettings' }));
        const result = JSON.parse(jsonData.body);
        setTimeDelay(result.timedelay);
        setTimeInterval(result.timeinterval);
        setEmbedding(result.embed_bool);
        setEmbeddingURL(result.embed_url);
    };

    const getEmbedding = async () => {
        const bodyData = JSON.stringify({
            'request_type': 'info',
            'info_type': 'embedding',
            'session_name': localStorage.getItem('selectedSession')
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        return result.embed_bool;
    }

    const startstopEmbedding = async () => {
        const embeddingBool = await getEmbedding();
        if (!embeddingBool) {
            var embedURL = document.getElementById('embedURLinput')?.value || '';
       
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'startembedding',
                'url': embedURL
            });
          await UserAPI(requestBody);
          setEmbedding(true);
          setEmbeddingURL(embedURL);
        }
        else {
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'stopembedding'
            });
            await UserAPI(requestBody);
            setEmbedding(false);
            setEmbeddingURL(null);
        }
    };

    const getSettings = async () => {
        try {
            if (!idToken) {
                console.error('No token available');
                return;
            }

            console.log('Getting settings');
            const jsonData = await UserAPI(JSON.stringify({ 
                'request_type': 'user', 
                'idToken': idToken,
                'user_request_type': 'getsettings' 
            }));
            
            if (!jsonData) return;
            
            const result = JSON.parse(jsonData.body);
            console.log(result);
            setTimeDelay(result.timedelay);
            setTimeInterval(result.timeinterval);
            setEmbedding(result.YT_embed_bool);
            setEmbeddingURL(result.YT_embed_URL);
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    useEffect(() => {
        getSettings();
    }, [idToken]);
    
    return (
        <div style={{ width: '100rem' }}>

            <div>
                
                <input 
                    id="timedelayinput" 
                    style={{ width: inputWidth }}
                    type="text" 
                    placeholder={`Enter (current value: ${timeDelay}s)`}
                />
                <button 
                    style={{ width: buttonWidth }}
                    onClick={() => changeSetting("timedelay")}
                >
                    Change Time Delay
                </button>
            </div>
            <div >
                
                <input 
                    id="timeintervalinput" 
                    style={{ width: inputWidth }}
                    type="text" 
                    placeholder={`Enter (current value: ${timeInterval}s)`}
                />
                <button 
                    style={{ width: buttonWidth }}
                    onClick={() => changeSetting("timeinterval")}
                >
                    Change Time Interval
                </button>
            </div>

            <div ></div>
            
            <input 
                    id="embedURLinput" 
                    type="text" 
                    style={{ width: inputWidth }}
                    placeholder={`Enter (current: ${embeddingURL})`}
                />

        <button 
            style={{ width: buttonWidth }}
            onClick={startstopEmbedding} 
            id="embeddingButton"
        >
              {embedding ? 'Stop embedding' : 'Start embedding'}
            </button>
        </div>
    );
};

export default SettingsMenuCreate;
