const Environment = "test"; // dev, dev1, test

const API_prefix = Environment === "test" ? "a8qlfevzp1" : 
                  Environment === "dev1" ? "htxi3ygyzk" : 
                  "htxi3ygyzk"; // default for dev


const AuthURL = Environment === "test" ? 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=k2ak0o89gb2lop4vntbbcbta9&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fmain.d258l7k8n242lz.amplifyapp.com%2Fcallback' : 
Environment === "dev1" ? 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=3ng1jhbo6oemarms0uc9mhadak&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback' : 
'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=3ng1jhbo6oemarms0uc9mhadak&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback';


const CognitoURL = Environment === "test" ? 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token' : 
                  Environment === "dev1" ? 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token' : 
                  'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token';

const RedirectURI = Environment === "test" ? 'https://main.d258l7k8n242lz.amplifyapp.com/callback' : 
                  Environment === "dev1" ? 'http://localhost:3000/callback' : 
                  'http://localhost:3000/callback';

const ClientID = Environment === "test" ? 'k2ak0o89gb2lop4vntbbcbta9' : 
                  Environment === "dev1" ? '3ng1jhbo6oemarms0uc9mhadak' : 
                  '3ng1jhbo6oemarms0uc9mhadak';

const EnvironmentVariables = {
    AuthURL: AuthURL,
    UserAPI_URL: `https://${API_prefix}.execute-api.eu-west-3.amazonaws.com/${Environment}/process-data`,
    ImageAnalysisAPI_URL: `https://${API_prefix}.execute-api.eu-west-3.amazonaws.com/${Environment}/process-image`,
    InfoAPI_URL: `https://${API_prefix}.execute-api.eu-west-3.amazonaws.com/${Environment}/info`,
    RedirectURI: RedirectURI,
    CognitoURL: CognitoURL,
    ClientID: ClientID
};

export default EnvironmentVariables;