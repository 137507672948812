import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import EmotionDisplay from './EmotionDisplay';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';
import { Title, Text } from './Typography';
import { formatTitleToDB, formatTitleToDisplay } from './Functions';
import { refreshTime } from '../utils/DisplaySettings';

const Leaderboard = () => {
    const [leaderBoard, setLeaderBoard] = useState([]);
    console.log('Leaderboard component rendered');

    const loadLeaderBoard = async () => {
        console.log('loadLeaderBoard called');
        const idToken = localStorage.getItem('idToken');
        const payloadBase64 = idToken.split('.')[1];
        const decodedPayload = JSON.parse(atob(payloadBase64.replace(/-/g, '+').replace(/_/g, '/')));
        const session_name = decodedPayload['cognito:username'];
        const requestBody = JSON.stringify({
            'request_type': 'info',
            'session_name': session_name,
            'info_type': 'leaderboard'
        });
        const jsonData = await InfoAPI(requestBody);
        if (jsonData) {
            const result = JSON.parse(jsonData.body);
            const newLeaderBoard = result.map(element => ({
                user: element[0],
                points: element[1],
                request: formatTitleToDisplay(element[3]) + ' by ' + formatTitleToDisplay(element[2])
            }));
            setLeaderBoard(newLeaderBoard);
        }
    };

    useEffect(() => {
        console.log('Setting up leaderboard interval');
        let mounted = true;
        let intervalId = null;

        const loadData = async () => {
            if (mounted) {
                await loadLeaderBoard();
            }
        };

        // Initial load
        loadData();

        // Set up interval
        intervalId = setInterval(() => {
            console.log('Interval triggered');
            loadData();
        }, refreshTime);

        // Cleanup function
        return () => {
            console.log('Cleaning up leaderboard interval');
            mounted = false;
            if (intervalId) {
                clearInterval(intervalId);
                console.log('Interval cleared');
            }
        };
    }, []); // Empty dependency array since we don't need any dependencies

    const resetLeaderBoard = async () => {
        console.log('Resetting leaderboard');
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'event',
            'event_type': 'startLeaderBoard'
        });
        await UserAPI(requestBody);
        await loadLeaderBoard();
    };

    const songGrant = async (index) => {
        console.log('Granting song to user:', leaderBoard[index].user);
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'event',
            'event_type': 'takesongrequest',
            'user_name': leaderBoard[index].user
        });
        await UserAPI(requestBody);
        await loadLeaderBoard();
    };

    return (
        <div style={{ width: '100rem' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={resetLeaderBoard}>Reset leader board</button>
                </div>
                <div style={{ margin: '1.25rem 0' }}></div>
                <div style={{ marginLeft: '1.25rem', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '0.625rem' }}>
                    <div style={{ flex: '1 1 25%' }}>Viewer</div>
                    <div style={{ flex: '1 1 25%' }}>Points</div>
                    <div style={{ flex: '1 1 25%' }}>Request</div>
                    <div style={{ flex: '1 1 25%' }}></div>
                </div>
                <hr />
                {leaderBoard.length > 0 && 
                leaderBoard.map((user, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.625rem', marginBottom: '0.625rem' }}>
                        <div style={{ flex: '1 1 25%' }}>{user.user}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.points}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.request}</div>
                        <button onClick={() => songGrant(index)} style={{ flex: '1 1 25%' }}>Grant</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Leaderboard;
