import EnvironmentVariables from './EnvironmentVariables';

export const fetchTokens = async (code) => {
    console.log('fetchTokens', code);
    if (!code) {
        throw new Error('No code provided');
    }

    try {
        const params = new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: EnvironmentVariables.ClientID,
            code: code,
            redirect_uri: EnvironmentVariables.RedirectURI
        });

        const response = await fetch(EnvironmentVariables.CognitoURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString()
        });

        const data = await response.json();

        // Check if we got an error response
        if (data.error) {
            console.log('Token error:', data.error);
            return null;
        }
        
        if (data.id_token && data.access_token) {
            localStorage.setItem('idToken', data.id_token);
            localStorage.setItem('accessToken', data.access_token);
            
            return {
                idToken: data.id_token,
                accessToken: data.access_token,
                refreshToken: data.refresh_token
            };
        }
        
        return null;

    } catch (error) {
        console.error('Error fetching tokens:', error);
        return null;
    }
};

export const checkAuthStatus = () => {
    const idToken = localStorage.getItem('idToken');
    return idToken ? true : false;
};
